<template>
  <div class="divback" id="divback">
    <div class="videos" id="videos" style="width: 650px; height: 350px;" >

      <video width="650" height="350" id="remoteVideo"
             style="object-fit:fill" preload="auto" autoplay="autoplay"
             x-webkit-airplay="true" playsinline="true" webkit-playsinline="true" x5-video-player-type="h5"
             x5-video-player-fullscreen="true" x5-video-orientation="portraint"
             controls
             v-if="isPlay"
      >
      </video>
      <div class="arc" id="loading" style="display: none;">
      </div>
    </div>




  </div>
</template>

<script>
import moment from "moment";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {axiosServer} from "@/utils/request";
export default {
  name: "JcWebrtcByBoli",
  props: ['videoUrl', 'statusShow'],
  data(){
    return{
      isPlay: true


    }
  },
  mounted() {
    // this.play();
    // this.$refs.video.addEventListener('loadstart', function() {
    //   console.log('提示视频的元数据已加载')
    // })
    // this.$refs.video.addEventListener('canplay', function() {
    //   console.log('canplay')
    // })
    // this.$refs.video.addEventListener('error', function (){
    //   console.log('An error occurred:', event);
    // })
    // this.$refs.video.addEventListener('loadedmetadata', function (){
    //   console.log('loadedmetadata:', event);
    // })

    // this.requestFileDays()
  },
  methods:{
    play(){
      console.log("my-webrtc play")
      var toclientid ="RHZL-00-AV08-STCV-00002610";
      var host ="webrtc.qq-kan.com";
      var conmode ="live";
      var consource = this.videoUrl.slice(-1);
      if(conmode === "live" || conmode === "play") {
        console.log()
      }else{
        conmode = "live"
        consource = "MainStream"
      }

      // document.addEventListener("WeixinJSBridgeReady",function() {
      //       document.getElementById('remoteVideo').play(); }
      //     , false);
      var remoteVideoview = document.querySelector('#remoteVideo');

      // eslint-disable-next-line no-undef
      RHRTCStart(host,toclientid,remoteVideoview,conmode,consource,true,true);

    }
  },
  watch: {
    videoUrl: {
      handler(newVal, oldVal) {


        if (newVal !== oldVal){
          // eslint-disable-next-line no-undef
          sendDisconnect();
       
          this.$nextTick(()=>{

            console.log("润华webrtc点播")
            this.play();
          })
        }

      },
      immediate: true
    }
  },
  beforeDestroy() {

  },
  destroyed() {
    console.log("webrtc销毁")
    // eslint-disable-next-line no-undef

  }
}
</script>



<style scoped >
.videos{
  display: flex;
  justify-content: center;
}
::v-deep .ant-btn{
  padding: 0 8px;
}
</style>