<template>
  <div class="amap-page-container">
    <!--  设备框  -->
    <dv-border-box-10 class="device" v-if="infoVisible">
      <div style="background-color: #0f1325; height: 100%">
        <div style="position: absolute; right: 0;cursor: pointer; top: -5px;" @click="infoVisible = false; devices = []; videoVisible = false; currentVideo=null">
          <img src="../assets/close.png" alt="">
        </div>
        <div style="font-size: 18px; text-align: center; margin-top: 5px; margin-bottom: 10px">{{info.name}}</div>
        <div class="device-box">
          <template v-if="devices.length === 0">
            <a-empty style="height: 100%;background-color: #0f1325; color: #fff" description="暂无记录" />
          </template>
          <template v-else>
            <a-card  style="margin-bottom: 5px;background-color: #0f132512; color: #ffffff;cursor: pointer "
                     v-for="(item, index) in devices" :key="index" @click="videoShow(item)">
              <template v-if="item.deviceType === 1">
                <div style="height: 50px; display:flex; flex-direction: column;justify-content: center; align-items: center">
                  <div>设备名称:{{item.deviceName}}</div>
                  <div>设备类型:{{item.deviceType_dictText}}</div>
                </div>
              </template>
              <template v-if="item.deviceType === 2">


                <a-popover :title="`${item.deviceName}指令下发`" trigger="click" placement="topRight">
                  <template slot="content">
                      <a-card :bordered="false" v-for="(item) in iotOrderDevice" :key="item.id">
                        <div style="display: flex;">
                          <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">
                            <template  v-if="item.needPassword === 1">
                              <a-popconfirm
                                  title="下发指令是一个危险的操作"
                                  ok-text="下发"
                                  cancel-text="取消"
                                  @confirm="sendOrder(item, it)"
                                  @cancel="cancelOrder"
                              >
                                <a-tag color="green" >
                                  {{ it.name }}
                                </a-tag>
                              </a-popconfirm>
                            </template>
                            <template v-else>
                              <a-tag color="green" @click="sendOrder(item, it)">
                                {{ it.name }}
                              </a-tag>
                            </template>
                          </div>
                        </div>
                      </a-card>

                  </template>
                  <div style="height: 50px; display:flex; flex-direction: column;justify-content: center; align-items: center">
                    <div>设备名称:{{item.deviceName}}</div>
                    <div>设备类型:{{item.deviceType_dictText}}</div>
                  </div>
                </a-popover>

              </template>

            </a-card>
          </template>
        </div>

      </div>
    </dv-border-box-10>
    <!--  告警框  -->
    <dv-border-box-10 class="alarm" >
      <div style="background-color: #0f1325; height: 100%">
        <div style="font-size: 18px; text-align: center; margin-top: 5px; margin-bottom: 10px">告警列表</div>
        <div class="alarm-box">
          <template v-if="alarms.length === 0">
            <a-empty style="height: 100%;background-color: #0f1325; color: #fff" description="暂无记录" />
          </template>
          <template v-else>
            <a-card  style="margin-bottom: 5px" v-for="(item, index) in alarms" :key="index">
              <div class="alarm-list">
                <div class="left"  >
                    <img :src="item.pictureUrl" v-if="item.pictureUrl" :preview="item.id" style="width: 130px; " >
                </div>
                <div class="right" style="flex:1;">
                  <div>告警时间:{{item.alarmTime}}</div>
                  <div>告警设备:{{item.deviceId_dictText}}</div>
                  <div>告警单位:{{item.jcCompanyId_dictText}}</div>
                  <div>告警类型:{{item.alarmTypeText }}</div>
                </div>
              </div>
            </a-card>
          </template>

        </div>
      </div>
    </dv-border-box-10>
    <!--  视频框  -->
    <dv-border-box-10 class="video" v-if="videoVisible">
      <div style="position: absolute; right: 0;cursor: pointer; top: -5px; z-index: 9998" @click="videoVisible = false; currentVideo = null">
        <img src="../assets/close.png" alt="">
      </div>
      <div class="video-box">
        <jessibucaDemo v-if="videoType === 1" :video-url="currentVideo.wssUrl"  ></jessibucaDemo>
        <jcWebrtcByBoli  v-if="videoType === 3" ref="webrtc" :video-url="currentVideo.wssUrl" :status-show="false"></jcWebrtcByBoli>
      </div>
      <div class="content1">
        <a-card class="item" v-for="(item) in orderDevice" :key="item.id">
          <div  :title="item.deviceName">名称: {{item.deviceName.replace("摄像头", "...")}}</div>
          <!--         <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style=" margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <template v-if="item.option.length === 0">
              <div style="visibility: hidden">
                <a-tag color="green" >
                  hello
                </a-tag>
              </div>
            </template>
            <template v-else>
              <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">

                <template  v-if="item.needPassword === 1">
                  <a-popconfirm
                      title="下发指令是一个危险的操作"
                      ok-text="下发"
                      cancel-text="取消"
                      @confirm="sendOrder(item, it)"
                      @cancel="cancel"

                  >
                    <a-tag color="green" >
                      {{ it.name }}
                    </a-tag>
                  </a-popconfirm>
                </template>
                <template v-else>
                  <a-tag color="green" @click="sendOrder(item, it)">
                    {{ it.name }}
                  </a-tag>
                </template>
              </div>
            </template>
          </div>
        </a-card>
      </div>
    </dv-border-box-10>
    <!--  搜索框  -->

    <el-amap  :center="center" :zoom="zoom"
              @init="init" class="amap-demo"

              mapStyle="amap://styles/darkblue"
    >
<!--      <el-amap-mass-marks :data="massPoints" :visible="massVisible" :styles="massStyle" @init="massMarkerInit" @click="massClickMarker">-->
<!--      </el-amap-mass-marks>-->
      <el-amap-marker
          v-for="(marker, index) in massPoints"
          :key="index" :position="marker.position"
          @click="(e) => {massClickMarker(marker, e)}"
          :zIndex="5"
          icon="https://jc-app-store-1258271165.cos.ap-guangzhou.myqcloud.com/icon%2Fmark_normal.png"></el-amap-marker>

      <el-amap-info-window
          :position="infowindowPosition"
          :visible.sync="infowindowVisible"
          :offset="[20,0]"

      >
        <div style="width: 200px; height: 120px;color: #0f1325;">
          <div>单位名称:{{infowindow.comName}}</div>
          <div>行政区划:{{infowindow.provinceName }}{{infowindow.cityName}}{{infowindow.areaName}}{{infowindow.streetName}}</div>
          <div>详细地址:{{infowindow.address}}</div>
        </div>
      </el-amap-info-window>

    </el-amap>
  </div>
</template>


<script>
import {axiosServer} from "@/utils/request";
import jessibucaDemo from "@/views/monitor/JessibucaDemo.vue";
import jcWebrtcByBoli from "@/views/monitor/JcWebrtcByBoli.vue";
import {sendOrder} from "@/utils/commenRequset";

export default {
  name: "Map",
  components: {jcWebrtcByBoli, jessibucaDemo},
  data(){
    return{
      zoom: 12,
      center: [114.12374, 22.670216],
      map: null,

      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      // maker
      massVisible: false,
      massPoints: [],
      massStyle:{},
      // info
      infoVisible: false,

      info: {},
      //video
      videoVisible: false,
      currentVideo: {
        wssUrl: null
      },
      videoType: 0,
      //iot
      iotVisible: false,
      // infowindow
      infowindowVisible: false,
      infowindowPosition:[114.12374, 22.670216],
      infowindow:{},
      //
      alarms:[],
      devices:[],
      iotOrderDevice: [],
      orderDevice: [],

    }
  },
  mounted() {
    this.initLocation();
    this.initAlarms();
  },
  methods:{
    sendOrder,
    init(o) {
      this.map = o;
    },
    initDevice(comId){
      this.deviceList = [];
      axiosServer({
        url: "/jc/jcDevice/list",
        params: {
          jcCompanyId: comId || 0,
          pageSize: 100
        },
        type:"GET",
      }).then(res => {
        console.log("initDevice1：",res);
        this.devices = res.result.records.sort((a, b) => a.deviceType - b.deviceType);
      })
    },
    initAlarms(){
      axiosServer({
        url: "/jc/jcAlarm/list",
        params: {
          column: 'createTime',
          page: 1,
          pageSize: 20,
          order: "desc"
        },
        type:"GET",
      }).then(res => {
        console.log("initAlarms：",res);
        if (res.code === 200){
          this.alarms = res.result.records;
        }

      })
    },
    initLocation(){
      axiosServer({
        url: '/jc/jcGeneral/get/company/location?orgCode=' + this.userInfo.orgCode,
        type: 'GET',
      }).then(res => {
        console.log(res)
        res.result.map(item => {
          this.massPoints.push({
            position: [item.lng, item.lat],
            id: item.id,
            name: item.comName,
          })
        })
      })
    },
    initCompanyInfo(id, isMove = false){
      axiosServer({
        url: '/jc/jcCompany/queryById',
        type: 'GET',
        params:{
          id: id
        }
      }).then(res => {
        console.log(res)
        this.infowindow = res.result;

        if (isMove){
          console.log("执行地图移动")
          this.$nextTick(() => {
            this.center = [ res.result.lng, res.result.lat];
          })
        }
        this.infowindowPosition = [ res.result.lng,  res.result.lat];
        this.infowindowVisible = true;
      })
    },
    massClickMarker(maker, e){
      this.info = maker;
      this.center = [ maker.position[0], maker.position[1]];
      this.initDevice(maker.id);
      this.initCompanyInfo(maker.id);
      this.infoVisible = true;
      console.log("massClickMarker:",maker, e)
    },
    initRelationDevice(deviceId){
      this.orderDevice = [];
      axiosServer({
        url: "/jc/jcGeneral/relation/device/one",
        params: {
          deviceId:deviceId
        },
        type:"POST",
      }).then(res => {
        console.log("initRelationDevice：",res);

        this.orderDevice = res.result;
      })
    },
    initRelationDevice1(deviceId){
      this.iotOrderDevice = [];
      axiosServer({
        url: "/jc/jcGeneral/relation/device/one",
        params: {
          deviceId:deviceId
        },
        type:"POST",
      }).then(res => {
        console.log("initRelationDevice：",res);

        this.iotOrderDevice = res.result;
      })
    },
    videoShow(record){
      if (record.deviceType === 1){
        let newVal = record.wssUrl;
        if (newVal){
          // 默认置1
          this.videoType = 1;
          if (newVal.includes("120.226.39.61")){
            this.videoType = 0;
            // this.videoDialogVisible = true;
            window.open(newVal, '_blank', '');
            return;
          }
          if (newVal.includes("webrtc")){
            console.log("webrtc")
            this.videoType = 3;

          }
          if (newVal.includes("main_stream.jpeg")){
            this.videoType = 2;
          }
          this.currentVideo = record;
          this.videoVisible = true;
          this.initRelationDevice(record.id);
        }else {
          this.videoType = 0;
        }
      }else if (record.deviceType === 2){

        this.initRelationDevice1(record.id);
      }else {
        this.videoVisible = false;
        this.currentVideo= null;
      }
    },

    cancelOrder(){

    },
  }
}
</script>


<style scoped lang="scss">
.amap-demo {
  padding: 15px;
  height: 900px;
  width: 100%;
}
.device{
  width: 350px;
  height: 800px;
  //background-color: #f5222d;
  padding: 8px;
  position: absolute;
  left: 10px;
  top: 50px;
  z-index: 9999;

}
.alarm{
  width: 350px;
  height: 800px;
  //background-color: #f5222d;
  padding: 8px;
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 9999;
  box-shadow: #f5222d 0px 0px 25px 3px inset!important;
}
.video{
  background-color: #0f1325;
  width: 900px;
  height: 620px;
  position: absolute;
  z-index: 9999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  .video-box{
    width: 900px;
    height: 520px;
  }
}
.iot{
  background-color: #0f1325;
  width: 300px;
  height: 200px;
  position: absolute;
  z-index: 9999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .ant-card-body{
  padding: 0;
}
.alarm-box{
  overflow-y: scroll;
  height: 750px;
  .alarm-list{
    display: flex;
    min-height: 80px;
    .left{
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
}
.alarm-box::-webkit-scrollbar {
  width: 0;
}
.alarm-box::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(0,0,0,0.2);
}
.alarm-box::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}
.device-box{
  overflow-y: scroll;
  height: 750px;
}
.device-box::-webkit-scrollbar {
  width: 0;
}
.device-box::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(0,0,0,0.2);
}
.device-box::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}
.content1{
  display: flex;

  .item{
    padding: 3px;
    //margin-top: 10px;
    margin-left: 5px;
    margin-right: 10px;
    display: inline-block;
    background-color: rgb(13, 27, 88);
    color: #fff;
    min-width: 150px;
    height: 90px;
  }
}
</style>