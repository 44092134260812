console.log(process.env.NODE_ENV,process.env.NODE_ENV == 'development' ? "测试环境": "线上环境")


let httpUrl = "http://localhost:15000/jc-charging";
let wsUrl = "ws://localhost:15000/jc-charging" ;

if (process.env.NODE_ENV == "production"){
     httpUrl = "https://v3fire.jckjcn.com/jc-charging";
     wsUrl = "wss://v3.jckjcn.com/jc-charging" ;
}

if (process.env.NODE_ENV == "prodyd"){

     httpUrl = "https://v4fire.jckjcn.com/jc-charging";
     wsUrl = "wss://v4.jckjcn.com/jc-charging" ;
}

const baseurl = {
    httpUrl: httpUrl,
    wsUrl: wsUrl,
}


export default baseurl;